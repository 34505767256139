import React, { useState } from "react";
import { toast, Bounce } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { toggleLoginModal } from "../../redux/toggle/toggle.action";
import { useForm } from "react-hook-form";
import { postData } from "../../api/apiService";

function DepositeForm({ DepositeData }) {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState();
  const formConfig = [
    {
      name: "utr",
      label: "Unique Transaction Reference",
      type: "text",
      required: true,
      message: "Please enter your UTR ID",
    },
    {
      name: "proof",
      label: "Upload Your Payment Proof",
      type: "file",
      required: true,
      message: "Please Upload Payment Proof.",
    },
    {
      name: "amount",
      label: "Amount",
      type: "number",
      required: true,
      message: "Please enter amount",
      min: DepositeData?.min_deposit_amount,
      max: DepositeData?.max_deposit_amount,
    },
    {
      name: "tnc_flag",
      label:
        "I have read and agree with the terms of payment and withdrawal policy.",
      type: "checkbox",
      required: true,
      message: "Please check terms and condition",
    },
  ];
  const onSubmit = async (value) => {
    setLoading(true);
    const formDataRaw = {
      deposit_account_id: DepositeData?.id,
      ...value,
    };
    const formData = new FormData();
    formData.append("deposit_account_id", DepositeData?.id);
    formData.append("utr", value.utr);
    formData.append("proof", value.proof);
    formData.append("amount", value.amount);
    formData.append("tnc_flag", 1);
    // setLoading(true);
    postData("/deposits", formData)
      .then((response) => {
        toast.success("Deposit Request Submitted Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        reset();
        setFileData();
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const handleFile = (name, e) => {
    const file = e.target.files[0];
    setValue(name, file);
    setFileData(file);
  };

  return (
    <div>
      <div>
        <form
          disabled={loading}
          onSubmit={handleSubmit(onSubmit)}
          enctype="multipart/form-data"
          className="w-full md:mx-auto"
        >
          {formConfig.map((field, index) => (
            <div key={index} className="mb-4">
              {field.type !== "checkbox" && (
                <label
                  className="block text-grey-darker text-sm font-medium mb-2"
                  htmlFor={field.name}
                >
                  {field.label}
                </label>
              )}

              {(field.type === "text" || field.type === "number") && (
                <>
                  <input
                    {...register(field.name, {
                      required: field.required ? field?.message : false,
                    })}
                    type={field.type}
                    id={field.name}
                    min={field?.min}
                    max={field?.max}
                    className={`appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline ${
                      errors[field.name] ? "border-red-500" : ""
                    }`}
                    placeholder={`Enter ${field.label}`}
                  />
                </>
              )}
              {field.type === "checkbox" && (
                <div className="flex ">
                  <input
                    {...register(field.name, {
                      required: field.required ? field?.message : false,
                    })}
                    type={field.type}
                    id={field.name}
                    className={`border rounded py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline ${
                      errors[field.name] ? "border-red-500" : ""
                    }`}
                    placeholder={`Enter ${field.label}`}
                  />
                  <label
                    className="ml-2 block text-grey-darker text-sm font-medium mb-2"
                    htmlFor={field.name}
                  >
                    {field.label}
                  </label>
                </div>
              )}
              {field.type === "file" && (
                <>
                  <div className="relative">
                    <input
                      ref={register(field.name, {
                        required: field.required ? field?.message : false,
                      })}
                      // {...register(field.name, { required: true})}
                      type={field.type}
                      id={field.name}
                      onChange={(e) => handleFile(field.name, e)}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                    />
                    <button className="py-2 px-4 bg-main text-white font-semibold rounded-md shadow-sm">
                      Choose File
                    </button>
                    <p>{fileData?.name}</p>
                  </div>
                </>
              )}
              {errors[field.name] && (
                <p className="text-red text-xs italic">
                  {errors[field.name]?.message}
                </p>
              )}
            </div>
          ))}
          <div className="flex flex-col gap-y-3 items-center justify-between">
            <button
              disabled={loading}
              type="submit"
              className="bg-maincard w-full hover:bg-maincard-700 text-body font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:bg-gray-200 disabled:text-gray-300 disabled:cursor-not-allowed"
            >
              {loading ? "Please Wait.." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    isLoginModalOpen: state.toggleReducer.isLoginModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLoginModal: (payload) => dispatch(toggleLoginModal(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositeForm);
