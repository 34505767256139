import React, { useState } from "react";

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="flex items-center justify-center border-b">
        {tabs?.map(({ title }, index) => (
          <button
            key={index}
            className={`px-4 py-2 mr-2 text-sm font-medium rounded-t-lg ${
              activeTab === index ? "text-white bg-main " : "text-main bg-box1"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {title}
          </button>
        ))}
      </div>
      <div className="mt-4">{tabs[activeTab].content}</div>
    </div>
  );
};

export default Tabs;
