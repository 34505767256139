import React, { useState } from "react";
import Modal from "../Lib/Model";
import { toast, Bounce } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { toggleLoginModal } from "../../redux/toggle/toggle.action";
import { useForm } from "react-hook-form";
import { fetchData, login } from "../../api/apiService";
import { setUser } from "../../redux/auth/auth.action";
import Loader from "../Lib/Loader";

function Login({ isLoginModalOpen }) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const formData = [
    { name: "username", label: "User Name", type: "email", required: true },
    { name: "password", label: "Password", type: "password", required: true },
  ];
  const onSubmit = async (value) => {
    setLoading(true);
    login(value?.username, value?.password)
      .then(async (loginResponse) => {
        if (loginResponse) {
          const usr = await fetchData("/user");
          dispatch(setUser(usr));
          dispatch(toggleLoginModal(false));
          toast.success("Login successful!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
        setLoading(false);
        window.location.reload()
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        width={"1/2"}
        isOpen={isLoginModalOpen}
        onClose={() => dispatch(toggleLoginModal(false))}
      >
        {loading && <Loader />}
        <div className="grid gap-y-2 grid-cols-1 lg:grid-cols-5">
          <div className="hidden lg:block col-span-2">
            <img
              className="rounded-l w-full h-full object-cover"
              src="/assets/img/login-banner.jpeg"
              alt="login banner 1"
            />
          </div>
          <div className="flex flex-col justify-center items-center col-span-5 lg:col-span-3">
            <div className="w-3/4 mx-auto py-20">
              <div className="text-gray-600 mb-16">
                <p className="font-bold text-3xl text-center">
                  Sign in to your account
                </p>
              </div>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="w-full md:mx-auto"
              >
                {formData.map((field, index) => (
                  <div key={index} className="mb-4">
                    <label
                      className="block text-grey-darker text-sm font-medium mb-2"
                      htmlFor={field.name}
                    >
                      {field.label}
                    </label>
                    {field.type === "textarea" ? (
                      <textarea
                        {...register(field.name, { required: field.required })}
                        id={field.name}
                        rows="4"
                        className={`appearance-none border rounded w-full py-2 px-3 text-body font-medium leading-tight focus:outline-none focus:shadow-outline ${
                          errors[field.name] ? "border-red-500" : ""
                        }`}
                        placeholder={`Enter ${field.label}`}
                      ></textarea>
                    ) : (
                      <input
                        {...register(field.name, { required: field.required })}
                        type={field.type}
                        id={field.name}
                        className={`appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline ${
                          errors[field.name] ? "border-red-500" : ""
                        }`}
                        placeholder={`Enter ${field.label}`}
                      />
                    )}
                    {errors[field.name] && (
                      <p className="text-red text-xs italic">
                        Field is required
                      </p>
                    )}
                  </div>
                ))}
                <div className="flex items-center justify-end mb-16">
                  <p className="text-line2 font-medium">
                    <a href="/forget-password">forget password?</a>
                  </p>
                </div>
                <div className="flex flex-col gap-y-3 items-center justify-between">
                  <button
                    type="submit"
                    className="bg-maincard w-full hover:bg-maincard-700 text-body font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Submit
                  </button>

                  <p>Or</p>

                  <button
                    onClick={(e) => e.preventDefault()}
                    className="bg-main w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Sign in with demo ID
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
    isLoginModalOpen: state.toggleReducer.isLoginModalOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLoginModal: (payload) => dispatch(toggleLoginModal(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
