import React from 'react'
import { toast } from 'react-toastify'

export default function BankDetails({DepositeData}) {

  const CopyDetail =  ({info}) => {
    return (<div className='justify-self-end'>
        <button onClick={() => toast(`copied - ${info}`)} className='btn-copy' data-clipboard-text={info}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.8 18C17.9201 18 18.4802 18 18.908 17.782C19.2843 17.5903 19.5903 17.2843 19.782 16.908C20 16.4802 20 15.9201 20 14.8V5.2C20 4.0799 20 3.51984 19.782 3.09202C19.5903 2.71569 19.2843 2.40973 18.908 2.21799C18.4802 2 17.9201 2 16.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.07989 8 5.2M7.2 22H12.8C13.9201 22 14.4802 22 14.908 21.782C15.2843 21.5903 15.5903 21.2843 15.782 20.908C16 20.4802 16 19.9201 16 18.8V9.2C16 8.07989 16 7.51984 15.782 7.09202C15.5903 6.71569 15.2843 6.40973 14.908 6.21799C14.4802 6 13.9201 6 12.8 6H7.2C6.0799 6 5.51984 6 5.09202 6.21799C4.71569 6.40973 4.40973 6.71569 4.21799 7.09202C4 7.51984 4 8.07989 4 9.2V18.8C4 19.9201 4 20.4802 4.21799 20.908C4.40973 21.2843 4.71569 21.5903 5.09202 21.782C5.51984 22 6.07989 22 7.2 22Z" stroke="black" stroke-width="2"/>
        </svg>
        </button>
      </div>)
}

  return (
    <div className='bg-white p-4 rounded-md'>
      <div className='grid grid-cols-3 justify-items-stretch gap-x-3 mb-2'>
        <div className='font-bold'>
          Bank Name:
        </div>
        <div className=''>
          {DepositeData?.institution_name}
        </div>
       <CopyDetail info={DepositeData?.institution_name} />
      </div>
      <div className='grid grid-cols-3 gap-x-3 mb-2'>
        <div className='font-bold'>
         A/C No:
        </div>
        <div className=''>
          {DepositeData?.account_number}
        </div>
        <CopyDetail info={DepositeData?.account_number} />
      </div>
      <div className='grid grid-cols-3 gap-x-3 mb-2'>
        <div className='font-bold'>
        IFSC Code:
        </div>
        <div className=''>
          {DepositeData?.institution_number}
        </div>
        <CopyDetail info={DepositeData?.institution_number} />
      </div>
      <div className='grid grid-cols-3 gap-x-3 mb-2'>
        <div className='font-bold'>
        Account Name:
        </div>
        <div className=''>
          {DepositeData?.account_name}
        </div>
        <CopyDetail info={DepositeData?.account_name} />
      
      </div>
      <div className='grid grid-cols-3 gap-x-3 mb-2'>
        <div className='font-bold'>
        Min Amount:
        </div>
        <div className=''>
          {DepositeData?.min_deposit_amount}
        </div>
      </div>
      <div className='grid grid-cols-3 gap-x-3 mb-2'>
        <div className='font-bold'>
        Max Amount:
        </div>
        <div className=''>
          {DepositeData?.max_deposit_amount}
        </div>
      </div>
    </div>
  )
}
