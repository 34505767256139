import React from "react";

export default function Table(props) {
  const { id, columns, data } = props;
  return (
    <>
      <table className="size-full border-collapse mt-4 border border-white bg-line1">
        <thead>
          <tr>
            {columns.map(({ path, name }) => (
              <th className="border border-white bg-box2 py-1" key={path}>
                <span>{name}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="content-center">
          {data.length > 0 ? (
            data.map((rowData, index) => (
              <tr
                className={rowData.bet_type === "lay" ? "text-red" : ""}
                key={index}
              >
                {columns.map(({ path }) => (
                  <td className="border border-white px-4 py-1" key={path}>
                    <span
                      className={
                        path === "result" || path === "amount" || path === "balance" || path === "total"
                          ? rowData[path] < 0
                            ? "text-red"
                            : "text-green-500"
                          : ""
                      }
                    >
                      {rowData[path]}
                    </span>
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td className="border border-white px-4 py-1">
                <span>No Data To Display</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
