import { connect } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./screens/Home.jsx";
import NotFound from "./components/NotFound.jsx";
import PrivateRoute from "./components/PrivateRoute.jsx";
import Profile from "./screens/Profile.jsx";
import ScalableForm from "./components/Forms/ScalableForm.jsx";
import FormEample from "./components/Forms/FormEample.jsx";
import LoginTest from "./components/Forms/LoginTest.jsx";
import MyBets from "./screens/Private/MyBets.jsx";
import ProfitLoss from "./screens/Private/ProfitLoss.jsx";
import { ToastContainer } from "react-toastify";
import CricketMatchScreen from "./screens/CricketMatchScreen.jsx";
import AccountStatement from "./screens/Private/AccountStatement.jsx";
import Withdraw from "./screens/Private/Withdraw.jsx";
import Deposite from "./screens/Private/Deposite.jsx";
import MarketAnalysis from "./screens/Private/MarketAnalysis.jsx";
import ChangePassword from "./screens/Private/ChangePassword.jsx";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/game-detail/:id"
            element={<CricketMatchScreen />}
          />
          <Route exact path="/form" element={<ScalableForm />} />
          <Route exact path="/form-example" element={<FormEample />} />
          <Route exact path="/login-test" element={<LoginTest />} />
          <Route exact path="/market-analysis" element={<MarketAnalysis />} />
          <Route
            exact
            path="/deposit"
            element={
              <PrivateRoute>
                <Deposite />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/mybets"
            element={
              <PrivateRoute>
                <MyBets />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/withdraw"
            element={
              <PrivateRoute>
                <Withdraw />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/change-password"
            element={
              <PrivateRoute>
                <ChangePassword />
              </PrivateRoute>
            }
          />
          {/* <Route path="/mybets" element={<MyBets />} /> */}
          <Route path="/profit-loss" element={<ProfitLoss />} />
          <Route path="/account-statement" element={<AccountStatement />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <ToastContainer />
      </Router>
    </>
  );
}

// const mapStateToProps = (state) => {
//   return {};
// };

// const mapDispatchToProps = (dispatch) => {
//   return {};
// };

export default connect()(App);
