import { TOGGLE_LOGIN_MODAL, TOGGLE_SIGNUP_MODAL } from "./toggle.type";
export const toggleLoginModal = (payload) => async (dispatch) => {
  dispatch({
    type: TOGGLE_LOGIN_MODAL,
    payload: payload,
  });
};

export const toggleSignUpModal = (payload) => async (dispatch) => {
  dispatch({
    type: TOGGLE_SIGNUP_MODAL,
    payload: payload,
  });
};
