import React from 'react';
import { login, fetchData, logout } from '../../api/apiService';
// delete this afterwads

const LoginText = (props) => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    let [user, setUser] = React.useState({});

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (await login(email, password)) {
            setUser(await fetchData("/user"));
        }
    }

    const logoutTry = async (e) => {
        e.preventDefault();
        if (await logout()) {
            setUser({});
        }
    }
    return (
        <div>
            <div class="w-full max-w-xs">
                <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                            Username
                        </label>
                        <input
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div class="mb-6">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
                            Password
                        </label>
                        <input
                            class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div class="flex items-center justify-between">
                        <input type="submit" value={'Login'} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" />
                    </div>
                </form>
            </div>
            <div>
                <pre>{JSON.stringify(user, null, 4)}</pre>

                {Object.keys(user).length > 0 ? (<button onClick={logoutTry}>Logout</button>) : null}
                
            </div>
        </div>
    );
}

export default LoginText;