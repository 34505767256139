import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import Table from "./Table";

export default function MarketAnalysis() {
  const [data, setData] = useState([]);

  const columns = [
    { path: "event_type", name: "Event Type" },
    { path: "event_name", name: "Event Name" },
    { path: "expose", name: "Expose" },
  ];
  
  return (
    <div>
      <NavBar />
      <div className="container m-10 mx-auto">
        <span className="col-span-5">
          <h2 className="font-bold text-lg">Market Analysis</h2>
        </span>
        <Table id="eventName" columns={columns} data={data} />
      </div>
    </div>
  );
}
