import React, { useState } from 'react'

export default function BankOptionsList({listData, handleChange}) {

const getBankSymbol = (type) => {
 if(type == 'bank_account'){
   return {
      Symbol:`./assets/icons/bank-icon.png`,
      name:"Bank Account"
    }
 }else if(type === "upi"){
    return {
      Symbol:`./assets/icons/upi-icon.png`,
      name:"UPI"
    };
 }else {
  return {
    Symbol:`./assets/icons/bank-icon.png`,
    name:"Bank"
  };
 }
}

  const handleSelection = (id, name) => {
      const selectedItem = listData?.length > 0 && listData?.filter(item => {
        return item?.id === id
      })
      const AddinSelectedItem = {
        ...selectedItem[0],
        name: name
      }
      handleChange(AddinSelectedItem)
  }

  return (
    <div className='mt-8 bg-backCard p-4 rounded-sm grid md:grid-cols-5 lg:grid-cols-6 gap-4'>
        {listData?.length > 0 && listData?.map((item, index) => {
          return <div key={index} onClick={() => handleSelection(item?.id, getBankSymbol(item?.account_type).name)} className='cursor-pointer bg-white rounded-md p-3 min-w-[150px] text-center'>
            <p className='text-sm'>{getBankSymbol(item?.account_type).name}</p>
            <img className='mx-auto' src={getBankSymbol(item?.account_type).Symbol} alt={getBankSymbol(item?.account_type).name} />
          </div>
        })}
    </div>
  )
}
