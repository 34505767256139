import { SET_USER, REMOVE_USER } from "./auth.type";

const INITIAL_STATE = {
  isUserLoggedIn: false,
  user: {
    email: "",
    id: null,
    name: "",
  },
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        isUserLoggedIn: true,
        user: action.payload,
      };
    case REMOVE_USER:
      return {
        ...state,
        isUserLoggedIn: false,
        user: {
          email: "",
          id: null,
          name: "",
        },
      };
    default:
      return state;
  }
};

export default authReducer;
