import { combineReducers } from "redux";
import alertReducer from "./alert/alert.reducer";
import toggleReducer from "./toggle/toggle.reducer";
import authReducer from "./auth/auth.reducer";
import mybetReducer from "./mybet/mybet.reducer";
import walletReducer from "./wallet/wallet.reducer";

const rootReducer = combineReducers({
  alert: alertReducer,
  toggleReducer,
  authReducer,
  mybetReducer,
  walletInfo: walletReducer,
});

export default rootReducer;
