import React, { useEffect, useState } from "react";
import moment from "moment";
import { fetchData } from "../../api/apiService";

export default function DepositRequestTable() {
  const [list, setList] = useState([]);

  const getDepositTableData = () => {
    fetchData(`/deposits`)
      .then((res) => {
        setList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDepositTableData();

    return () => {};
  }, []);

  return (
    <div>
      <div class="overflow-x-auto">
        <table class="table-auto w-full rounded-lg text-xs">
          <thead className="bg-main text-white">
            <tr>
              <th class="px-4 py-2">TRANSACTION NO</th>
              <th class="px-4 py-2">AMOUNT</th>
              <th class="px-4 py-2">STATUS</th>
              <th class="px-4 py-2">DATE</th>
            </tr>
          </thead>
          <tbody>
            {list?.length > 0 &&
              list?.map((item, key) => {
                return (
                  <tr key={key}>
                    <td class="border px-4 py-2">{item?.utr}</td>
                    <td class="border px-4 py-2">{item?.amount}</td>
                    <td class="border px-4 py-2">{item?.status}</td>
                    <td class="border px-4 py-2">
                      {moment(item?.created_at).format("MMM Do YY")}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
