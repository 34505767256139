import React from "react";
import { postData } from "../../api/apiService";
import { toast, Bounce } from "react-toastify";

function BankAccountDetails({ bankAccount }) {
  // const [isDeleteAcountModalOpen, setIsDeleteAcountModalOpen] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Form submitted:", e.target.amount.value, bankAccount);
    const data = {
      amount: e.target.amount.value,
      tnc_flag: true,
      to_account_id: bankAccount.id,
    };
    postData("/withdrawals", data)
      .then((res) => {
        toast.success("Withdraw Request Submitted Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        // console.log("Form submitted:", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // const handleDelete = () => {
  //   console.log("Acc details deleted"); // API call to delete acc details
  // }

  return (
    <div className="border rounded-xlarge bg-white p-6 mt-6 w-max">
      <h5 className=" text-center font-bold uppercase text-lg underline underline-offset-8">
        {bankAccount.account_name}
      </h5>
      <div className="border rounded-large bg-gray-200 p-4 mt-6 text-base">
        <p className="relative">
          {bankAccount.account_type === "upi" ? (
            <span> UPI ID: {bankAccount.account_number}</span>
          ) : (
            <span> Account number: {bankAccount.account_number}</span>
          )}
          {/* <button className="inline-block align-text-top absolute inset-y-0 right-0 w-10">
                  <img
                    className="w-[18px] mr-2"
                    src="/assets/icons/copy-icon.svg"
                    alt="copy-icon"
                  />
                </button> */}
        </p>
        <p className="mt-2 relative">
          <span className={bankAccount.account_type === "upi" ? "hidden" : ""}>
            IFSC Code : {bankAccount.institution_number}
          </span>
          {/* <button className="inline-block align-text-top absolute inset-y-0 right-0 w-10">
                  <img
                    className="w-[18px] mr-2"
                    src="/assets/icons/copy-icon.svg"
                    alt="copy-icon"
                  />
                </button> */}
        </p>
      </div>
      <form id="" method="post" className="" onSubmit={(e) => handleSubmit(e)}>
        <input
          type="number"
          className="w-full mt-3 p-4 border border-slate-500 rounded-md"
          placeholder="Enter amount"
          id="amount"
          name="amount"
          required={true}
          min="100"
        />
        <button
          type="submit"
          className="w-full mt-3 p-3 border border-slate-500 rounded-md bg-maincard text-lg"
        >
          WITHDRAW
        </button>
      </form>
      {/* <button
        className="absolute top-2 right-2 h-10 w-10 bg-red rounded-tr-large"
        onClick={() => handleDelete()}
      >
        <img
          className="w-[20px] inline-block"
          src="/assets/icons/delete-icon.svg"
          alt="delete-icon"
        />
      </button> */}
    </div>
  );
}

export default BankAccountDetails;
