import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import Table from "./Table";
import TableTop from "./TableTop";
import fetchedData from "./ProfitLossData.json";

export default function ProfitLoss() {
  const [toDate, setToDate] = useState(() => setDate(new Date()));
  const [fromDate, setFromDate] = useState(() =>
    setDate(new Date(new Date().setDate(new Date().getDate() - 24)))
  );
  const [data, setData] = useState([]);

  function setDate(date) {
    return (
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate() + 1).padStart(2, "0")
    );
  }

  const filterData = () => {
    const newData = fetchedData.filter((values) => {
        return (
          Date.parse(values.created_at) <= Date.parse(toDate) &&
          Date.parse(values.created_at) >= Date.parse(fromDate)
        );
    });
    setData(newData);
  };

  useEffect(() => {
    filterData();
  }, []);

  const columns = [
    { path: "sr_no", name: "Sr No" },
    { path: "created_at", name: "Date" },
    { path: "event_type_name", name: "Event Type" },
    { path: "event_name", name: "Event" },
    { path: "amount", name: "Amount" }
  ];

  return (
    <div>
      <NavBar />
      <div className="container m-10 mx-auto">
        <TableTop
          pageType = "ProfitLoss"
          toDate={toDate}
          setToDate={setToDate}
          fromDate={fromDate}
          setFromDate={setFromDate}
          filterData={filterData}
        />
        <Table id="eventName" columns={columns} data={data} />
      </div>
    </div>
  );
}
