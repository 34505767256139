import { toast, Bounce } from "react-toastify";
import Cookies from "js-cookie";

export const handleApiError = (error) => {
  let errorMessage = "An error occurred";
  console.log("error", error);
  if (error.response) {
    // if (error.response.status === 401) {
    //   Cookies.remove("XSRF-TOKEN", {
    //     path: "/",
    //     domain: window.location.hostname,
    //   });
    //   Cookies.remove("laravel_session", {
    //     path: "/",
    //     domain: window.location.hostname,
    //   });
    // }
    // The request was made and the server responded with a status code
    errorMessage = error.response.data.message || error.response.statusText;
  } else if (error.request) {
    // The request was made but no response was received
    errorMessage = "No response received from the server";
  } else {
    // Something happened in setting up the request that triggered an error
    errorMessage = error.message;
  }
  toast.error(errorMessage, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
  });
  // return new Error(errorMessage);
};
