import React, { useEffect, useState } from "react";

export default function EventButtonGroup({
  Id,
  SelectionId,
  ExchangePrices,
  type,
  handleClick,
  data,
  index,
}) {
  const [runner, setRunnder] = useState(ExchangePrices);
  useEffect(() => {
    if (type === "back") {
      setRunnder(ExchangePrices);
    } else {
      setRunnder(ExchangePrices);
    }
  }, [ExchangePrices]);

  const handleClickEvent = (
    Id,
    SelectionId,
    rate,
    price,
    type,
    data,
    index
  ) => {
    console.log("~~~ ", data.Status, price, rate);
    if (data.Status === "ACTIVE") {
      if (price != (null || undefined) && rate != (null || undefined)) {
        handleClick(Id, SelectionId, rate, price, type, data, index);
      } else {
        console.log("Bet is invalid");
      }
    }
  };

  return (
    <>
      <div
        onClick={() =>
          handleClickEvent(
            Id,
            SelectionId,
            runner[0]?.price,
            runner[0]?.size,
            type,
            data,
            0
          )
        }
        className={`p-2 w-1/2 cursor-pointer rounded flex flex-col items-center justify-center ${
          type === "lay" ? "bg-box2" : "bg-box1"
        }`}
      >
        <p className="odd-price text-lg md:text-base font-medium">
          {runner[0]?.price ?? "-"}
        </p>
        <p className="odd-volume text-xs ">{runner[0]?.size ?? "-"}</p>
      </div>
      <div
        onClick={() =>
          handleClickEvent(
            Id,
            SelectionId,
            runner[1]?.price,
            runner[1]?.size,
            type,
            data,
            1
          )
        }
        className={`p-2 w-1/2 cursor-pointer rounded hidden md:flex flex-col items-center justify-center ${
          type === "lay" ? "bg-box2" : "bg-box1"
        }`}
      >
        <p className="odd-price text-lg md:text-base font-medium">
          {runner[1]?.price ?? "-"}
        </p>
        <p className="odd-volume text-xs">{runner[1]?.size ?? "-"}</p>
      </div>
      <div
        onClick={() =>
          handleClickEvent(
            Id,
            SelectionId,
            runner[2]?.price,
            runner[2]?.size,
            type,
            data,
            2
          )
        }
        className={`p-2 w-1/2 cursor-pointer rounded  hidden md:flex flex-col items-center justify-center ${
          type === "lay" ? "bg-box2" : "bg-box1"
        }`}
      >
        <p className="odd-price text-lg md:text-base font-medium">
          {runner[2]?.price ?? "-"}
        </p>
        <p className="odd-volume text-xs">{runner[2]?.size ?? "-"}</p>
      </div>
    </>
  );
}
