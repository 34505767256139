import React, { useState } from "react";

function WithdrawDetailsTable(props) {

  const {data, columns} = props;

  return (
    <div className="inline-block align-middle col-span-12 lg:col-span-6 overflow-x-auto overflow-y-auto h-fit">
      <table className=" border-collapse mx-4 border border-white bg-line1 ">
        <thead>
          <tr>
            {columns.map(({ path, name }) => (
              <th className="border border-white bg-box2 py-1 p-4" key={path}>
                <span>{name}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="content-center">
          {data.length > 0 ? (
            data.map((withdrawalsData, index) => (
              <tr
                className="border border-white"
                key={index}
              >
                {columns.map(({ path }) => (
                  <td className="border border-white p-1">
                    <span>{withdrawalsData[path]}</span>
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td className="border border-white px-4 py-1" colSpan="5">
                <span>No Data To Display</span>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default WithdrawDetailsTable;
