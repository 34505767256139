import React, { useEffect, useState } from "react";
import Tabs from "../Lib/Tabs";
import { fetchData } from "../../api/apiService";

export default function MyBetComponent() {
  const [betList, setBetList] = useState([]);
  const [fancyBetList, setFancyBetList] = useState([]);

  const getBetList = () => {
    fetchData("/bets")
      .then((res) => {
        console.log("res", res);
        setBetList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFancyBetList = () => {
    fetchData("/fancy-bets")
      .then((res) => {
        console.log("res", res);
        setFancyBetList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getBetList();
    getFancyBetList();
  }, []);

  const List = (
    <>
      <div className="grid grid-cols-4 p-2 border-b font-bold">
        <div className="col-span-2">Selname</div>
        <div className="col-span-1">Odds</div>
        <div className="col-span-1">Stake</div>
      </div>
      {betList &&
        betList?.length > 0 &&
        betList.map((item, index) => {
          return (
            <div className="grid grid-cols-4 p-2 gap-x-6 border-b">
              <div className="col-span-2">{item?.our_team}</div>
              <div className="col-span-1">
                {parseFloat(item?.rate).toFixed(2)}
              </div>
              <div className="col-span-1">{item?.amount}</div>
            </div>
          );
        })}
    </>
  );

  const FancyList = (
    <>
      <div className="grid grid-cols-4 p-2 border-b font-bold">
        <div className="col-span-2">Selname</div>
        <div className="col-span-1">Odds</div>
        <div className="col-span-1">Stake</div>
      </div>
      {fancyBetList &&
        fancyBetList?.length > 0 &&
        fancyBetList.map((item, index) => {
          return (
            <div className="grid grid-cols-4 p-2 gap-x-6 border-b">
              <div className="col-span-2">{item?.session_name}</div>
              <div className="col-span-1">
                {parseFloat(item?.rate).toFixed(2)}
              </div>
              <div className="col-span-1">{item?.amount}</div>
            </div>
          );
        })}
    </>
  );

  const tabs = [
    { title: "Odds Bets", content: List },
    { title: "Fancy Bets", content: FancyList },
  ];

  return (
    <div>
      <div className="grid grid-cols-3 bg-box1 mt-4">
        <div className="col-span-1 bg-primary-header clip--90 p-3 relative">
          <p className="font-bold text-white">
            MY BETS
            {/* <span className='mx-2 bg-maincard p-2 text-main uppercase rounded-full text-xs'>CASHout</span> */}
          </p>
        </div>
        <div className="col-span-2"></div>
      </div>
      <div className="mt-4 ">
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
}
