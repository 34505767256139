import { TOGGLE_LOGIN_MODAL, TOGGLE_SIGNUP_MODAL } from "./toggle.type";

const INITIAL_STATE = {
  isLoginModalOpen: false,
  isSignUpModalOpen: false,
};

const toggleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalOpen: action.payload,
      };
    case TOGGLE_SIGNUP_MODAL:
      return {
        ...state,
        isSignUpModalOpen: action.payload,
      };
    default:
      return state;
  }
};

export default toggleReducer;
