import React from 'react'
import NavBar from '../components/NavBar'
import GameDetails from '../components/GameDetails'
import MyBetComponent from '../components/RightMenus/MyBet'
import BetSlipComponent from '../components/RightMenus/BetSlip'
import { connect } from 'react-redux'

function Home({isUserLoggedIn}) {
  return (
    <div>
        <NavBar />
        <div className='grid grid-cols-12 gap-x-4 md:px-12'>
        <div className='col-span-1'>
          </div>
          <div className='col-span-12 lg:col-span-11'>
          <div className='grid grid-cols-12 gap-x-4'>
          <div className='col-span-12 lg:col-span-8'>
          <GameDetails />
          </div>
          <div className='hidden lg:block col-span-12 lg:col-span-4'>
            <div className='sticky top-[30px]'>
              {isUserLoggedIn && <>
                <BetSlipComponent />
                <MyBetComponent />
              </>}
         
            </div>
          </div>
          </div>
          </div>
        
        </div>
        <div>
        
        </div>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    isLoginModalOpen: state.toggleReducer.isLoginModalOpen,
    isSignUpModalOpen: state.toggleReducer.isSignUpModalOpen,
    isUserLoggedIn: state.authReducer.isUserLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

