import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import { fetchData } from "../../api/apiService";
import AddBankAccount from "../../components/Withdrawal/AddBankAccount";
import BankAccountDetails from "../../components/Withdrawal/BankAccountDetails";
import WithdrawDetailsTable from "../../components/Withdrawal/WithdrawDetailsTable";
import WithdrawalRules from "../../components/Withdrawal/WithdrawalRules";

export default function Withdraw() {
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const columns = [
    { path: "amount", name: "AMOUNT" },
    { path: "status", name: "STATUS" },
    { path: "to_account_id", name: "ACCOUNT" },
    { path: "created_at", name: "DATE" },
    { path: "reason", name: "REASON" },
  ];
  const [isAddAcountModalOpen, setIsAddAcountModalOpen] = useState(false);

  const getWithdrawData = () => {
    fetchData("/withdrawals")
      .then((response) => {
        setWithdrawalData(response);
        // console.log(response);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getbankAccountsData = () => {
    fetchData("/accounts/withdrawal")
      .then((response) => {
        setBankAccounts(response);
        // console.log(response);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  (() => {
    if (withdrawalData.length > 0 && bankAccounts.length > 0) {
      withdrawalData.map((withdraw) => {
        const matchBankAccount = bankAccounts.find(
          (account) =>
            account.user_id === withdraw.user_id &&
            account.id === withdraw.to_account_id
        );
        // console.log(matchBankAccount, withdraw);
        withdraw.to_account_id = matchBankAccount
          ? matchBankAccount.account_number
          : withdraw.to_account_id;
      });
    }
  })();

  useEffect(() => {
    getWithdrawData();
    getbankAccountsData();
  }, []);

  return (
    <>
      <div>
        <NavBar />
        <div className="container m-10 ">
          <button
            className="flex items-center justify-between cursor-pointer p-4 py-2 border rounded-full hover:border-slate-500"
            onClick={() => setIsAddAcountModalOpen(!isAddAcountModalOpen)}
          >
            <img
              className="w-[15px] mr-2"
              src="/assets/icons/account-add-plus-button.svg"
              alt="add-account-icon"
            />
            ADD ACCOUNT
          </button>
        </div>
        <div className="m-10 grid grid-cols-12 gap-x-3 text-xs container px-12 w-auto">
          <div className="col-span-12 lg:col-span-6">
            <WithdrawalRules />
            <div className="overflow-x-auto">
              <div className="flex gap-2 w-max">
                {bankAccounts.map((bankAccount) => (
                  <BankAccountDetails bankAccount={bankAccount} />
                ))}
              </div>
            </div>
          </div>
          <WithdrawDetailsTable data={withdrawalData} columns={columns} />
        </div>
      </div>
      <AddBankAccount
        isAddAcountModalOpen={isAddAcountModalOpen}
        setIsAddAcountModalOpen={setIsAddAcountModalOpen}
      />
    </>
  );
}
