import { SET_BET_SLIP, REMOVE_BET_SLIP } from "./mybet.type";

const INITIAL_STATE = {
  isBetSlipOpen: false,
  bet: {
    type: "",
    price: 0,
    rate: 0,
  },
};

const mybetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BET_SLIP:
      return {
        ...state,
        isBetSlipOpen: true,
        bet: action.payload,
      };
    case REMOVE_BET_SLIP:
      return {
        ...state,
        isBetSlipOpen: false,
        bet: {
          type: "",
          price: 0,
          rate: 0,
        },
      };
    default:
      return state;
  }
};

export default mybetReducer;
