import React from "react";
import moment from "moment";
import GameStatusIcons from "../GameStatusIcons";
import BetButtonGroup from "./BetButtonGroup";
import { useNavigate } from "react-router-dom";

export default function EventsList({ data }) {
  const navigate = useNavigate();

  const handleNavigate = (data) => {
    navigate(`/game-detail/${data?.eventId}`, {
      state: {
        name: data?.eventName,
        date: data.time,
        eventId: data?.eventId,
        marketId: data?.marketIds,
      },
    });
  };
  const length = data?.markets[0]?.runners?.length;

  return (
    <div className="px-4">
      <div className="grid grid-cols-5 lg:grid-cols-6 items-center border-b-[1px] border-stone-300 py-2">
        <div
          onClick={() => handleNavigate(data)}
          className="col-span-3 lg:col-span-3 flex flex-col cursor-pointer"
        >
          <p className="text-body text-xs order-2 md:order-1">
            {moment(data.time).format("MMM Do, h:mm A")}
          </p>
          <p className="text-main font-semibold order-1 md:order-2">
            {data?.eventName}
          </p>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <GameStatusIcons data={data} />
        </div>
        <div className="col-span-5 lg:col-span-2 flex gap-2 justify-around">
          {length > 0 ? (
            <BetButtonGroup
              marketId={data?.markets[0]?.marketId}
              runners={data?.markets[0]?.runners[0]}
              type={"odds"}
              gameId={data?.markets[0]?.gameId}
            />
          ) : (
            <BetButtonGroup
              marketId={null}
              gameId={null}
              market={null}
              type={"odds"}
            />
          )}
          {length > 2 ? (
            <BetButtonGroup
              marketId={data.markets[0].marketId}
              gameId={data.markets[0].gameId}
              runners={data?.markets[0]?.runners[0]}
              type={"odds"}
            />
          ) : (
            <BetButtonGroup
              marketId={null}
              gameId={null}
              market={null}
              type={"draw"}
            />
          )}
          {length > 1 ? (
            <BetButtonGroup
              marketId={data?.markets[0]?.marketId}
              runners={data?.markets[0]?.runners[1]}
              type={"odds"}
              gameId={data?.markets[0]?.gameId}
            />
          ) : (
            <BetButtonGroup
              marketId={null}
              gameId={null}
              market={null}
              type={"odds"}
            />
          )}
        </div>
      </div>
    </div>
  );
}
