import React from "react";

export default function GameStatusIcons({ data }) {
  return (
    <div
      className="flex 
    items-center 
    justify-end gap-2 px-4"
    >
      {data?.isInPlay === 1 && (
        <div
          className="animate-ping"
          style={{
            background: "#46A245",
            width: "5px",
            height: "5px",
            borderRadius: "50%",
          }}
        ></div>
      )}
      {data?.isStream && (
        <img className="w-6 h-6" src="/assets/icons/live-icon.svg" alt="" />
      )}
      {data?.isFancy && (
        <div
          className="flex 
       items-center 
       justify-center 
       w-6 h-6 
       bg-line2 text-center 
       font-bold text-main 
       uppercase rounded-lg text-xs"
        >
          F
        </div>
      )}
      {data?.isBm && (
        <div
          className="flex 
       items-center 
       justify-center 
       w-6 h-6 
       bg-line2 text-center 
       font-bold text-main 
       uppercase rounded-lg text-xs"
        >
          BM
        </div>
      )}
      {data?.isPremium && (
        <div
          className="flex 
       items-center 
       justify-center 
       w-6 h-6 
       bg-line2 text-center 
       font-bold text-main 
       uppercase rounded-lg text-xs"
        >
          P
        </div>
      )}
    </div>
  );
}
