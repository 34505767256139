import React from "react";

export default function EventsHeader({ eventName, eventIcon }) {
  return (
    <div className="grid grid-cols-3 lg:grid-cols-6 items-center bg-line1 mt-4">
      <div className="col-span-2 lg:col-span-3 bg-primary-header p-4 relative">
        <p className="font-bold text-white">
          {eventName}
          {/* <span className='mx-2 bg-maincard p-2 text-main uppercase rounded-full text-xs'>CASHout</span> */}
        </p>
        <img
          className="absolute right-8 top-1 lg:top-2 w-[25px] lg:w-[40px]"
          src={eventIcon}
          alt="game-icon"
        />
      </div>
      <div className="col-span-1 lg:col-span-1">
        {/* <p className='text-xs text-center text-main'>MIN:100 - MAX:50K</p> */}
      </div>
      <div className="lg:hidden hidden col-span-2 lg:col-span-1"></div>
      <div className="col-span-5 lg:col-span-2  hidden md:flex justify-around gap-2">
        <p className="text-lg lg:text-xs text-main font-medium">1</p>
        <p className="text-lg lg:text-xs text-main font-medium">x</p>
        <p className="text-lg lg:text-xs text-main font-medium">2</p>
      </div>
    </div>
  );
}
