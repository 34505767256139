import React from "react";
import "./TableTop.css";

export default function TableTop(props) {
  // console.log(props);
  const {
    pageType,
    toDate,
    setToDate,
    fromDate,
    setFromDate,
    filterData,
    matched,
    setMatched,
    eventType,
    setEventType,
  } = props;

  const tableHeader = pageType === "MyBets"?"My Bets":(pageType === "ProfitLoss"?"Betting Profit And Loss":"Account Statement");

  const onSubmitFunction = (e) => {
    e.preventDefault();
    filterData();
  };

  return (
    <div>
      <form
        className="grid grid-cols-12 gap-3 items-end text-xs"
        onSubmit={(e) => onSubmitFunction(e)}
      >
        <div className="col-span-5">
          <h2 className="font-bold text-lg">{tableHeader}</h2>
        </div>
        <div className={pageType !== "MyBets" ?"invisible":""}>
          <label htmlFor="matched" className="mr-1">
            Matched
          </label>
          <input
            id="matched"
            type="radio"
            formcontrolname="is_matched"
            name="is_matched"
            autoComplete="off"
            checked={matched}
            onChange={() => setMatched(1)}
          />
        </div>
        <div className={pageType !== "MyBets" ?"invisible":""}>
          <label htmlFor="deleted" className="mr-1">
            Un Matched
          </label>
          <input
            id="deleted"
            type="radio"
            formcontrolname="is_matched"
            name="is_matched"
            autoComplete="off"
            checked={!matched}
            onChange={() => setMatched(0)}
          />
        </div>
        <div className={pageType !== "ProfitLoss" ?"":"invisible"}>
          <label htmlFor="reportType">Types</label>
          <select
            name="reportType"
            id="reportType"
            className="dateSelector"
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
          >
            <option value="ALL">All Sports</option>
            <option value="4">Cricket</option>
            <option value="1">Football</option>
          </select>
        </div>
        <div>
          <label htmlFor="fromDate">From</label>
          <input
            name="fromDate"
            id="fromDate"
            type="date"
            placeholder="Select Date"
            className="dateSelector"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            min="2018-01-01"
            max={toDate}
          />
        </div>
        <div>
          <label htmlFor="toDate">To</label>
          <input
            name="toDate"
            id="toDate"
            type="date"
            placeholder="Select Date"
            className="dateSelector"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            min={fromDate}
            max={new Date().toISOString().split("T")[0]}
          />
        </div>
        <div>
          <label htmlFor="searchButton" className="block mt-4"></label>
          <button id="searchButton" type="submit" className="searchButton">
            Search
          </button>
        </div>
      </form>
    </div>
  );
}
