import axios from "axios";
import { handleApiError } from "../utils/errorHandler";
import Cookies from "js-cookie";

const AUTH_URL = process.env.REACT_APP_BASE_BACKEND_URL;
const API_URL = `${AUTH_URL}/api`;

// auth client
const authClient = axios.create({
  baseURL: AUTH_URL,
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    Accept: "application/json",
  },
});

// api client
const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    Accept: "application/json",
  },
});

// auth methods

const csrfCall = async () => {
  const csrfResponse = await authClient.get("/sanctum/csrf-cookie");
  if (csrfResponse.status != 204) {
    // server error

    return false;
  }

  return true;
};

const logout = async () => {
  try {
    if (await csrfCall()) {
      // server error
    }

    const logoutResponse = await authClient.post("/logout");
    if (
      logoutResponse.status == 200 &&
      logoutResponse.data.status == "success"
    ) {
      // user is logged out
      // clear local store
      // redirect to home
      Cookies.remove("XSRF-TOKEN", {
        path: "/",
        domain: window.location.hostname,
      });
      Cookies.remove("laravel_session", {
        path: "/",
        domain: window.location.hostname,
      });
      // for now i am returning true
      return true;
    }

    return false;
  } catch (error) {
    Cookies.remove("XSRF-TOKEN", {
      path: "/",
      domain: window.location.hostname,
    });
    Cookies.remove("laravel_session", {
      path: "/",
      domain: window.location.hostname,
    });
    throw handleApiError(error);
  }
};

const login = async (username, password) => {
  try {
    if (await csrfCall()) {
      // server error
    }

    const loginResponse = await authClient.post("/login", {
      email: username,
      password: password,
    });

    if (loginResponse.status == 200 && loginResponse.data.status == "success") {
      // user is logged in
      // call api/user endpoint
      // store user details in local store
      // redirect to home

      // for now i am returning true
      return loginResponse;
    }

    return false;
  } catch (error) {
    throw handleApiError(error);
  }
};

const changePassword = async (oldPassword, newPassword, newPasswordRe) => {
  try {
    if (await csrfCall()) {
      // server error
    }
    const changePasswordResponse = await authClient.put("/password", {
      current_password: oldPassword,
      password: newPassword,
      password_confirmation: newPasswordRe,
    });

    if (
      changePasswordResponse.status == 200 &&
      changePasswordResponse.data.status == "success"
    ) {
      return changePasswordResponse;
    }
    return false;
  } catch (error) {
    console.log(error);
    throw handleApiError(error);
  }
};

const registerUser = async (name, email, password, password_confirmation) => {
  try {
    if (await csrfCall()) {
      // server error
    }

    const registerResponse = await authClient.post("/register", {
      name: name,
      email: email,
      password: password,
      password_confirmation: password_confirmation,
    });

    if (
      registerResponse.status == 200 &&
      registerResponse.data.status == "success"
    ) {
      // user is logged in
      // call api/user endpoint
      // store user details in local store
      // redirect to home

      // for now i am returning true
      return registerResponse;
    }

    return false;
  } catch (error) {
    throw handleApiError(error);
  }
};

// api methods

const fetchData = async (path) => {
  try {
    const response = await apiClient.get(path);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

const postData = async (path, data) => {
  try {
    //all POST request would call this
    if (await csrfCall()) {
      // server error
    }

    const response = await apiClient.post(path, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

const putData = async (path, data) => {
  try {
    //all POST request would call this
    if (await csrfCall()) {
      // server error
    }

    const response = await apiClient.put(path, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export {
  login,
  logout,
  fetchData,
  changePassword,
  registerUser,
  postData,
  putData,
};
