import { SET_BET_SLIP, REMOVE_BET_SLIP } from "./mybet.type";

export const setMyBet = (payload) => async (dispatch) => {
  dispatch({
    type: SET_BET_SLIP,
    payload: payload,
  });
};

export const removeMyBet = () => async (dispatch) => {
  dispatch({
    type: REMOVE_BET_SLIP,
  });
};
