import {SET_ALERT, REMOVE_ALERT } from './alert.type';

const INITIAL_STATE = {
    alertType:"",
    alertMsg:"",
    alertOpen:false
};

const alertReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        alertType: action.payload.alertType,
        alertMsg:action.payload.alertMsg,
        alertOpen:action.payload.alertOpen
      };
    case REMOVE_ALERT:
       return {
        ...state,
        alertOpen:false
      };
    default:
      return state;
  }
};

export default alertReducer;
