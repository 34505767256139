import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import Table from "./Table";
import TableTop from "./TableTop";
import fetchedData from "./MyBetsData.json";

export default function MyBets() {
  const [toDate, setToDate] = useState(() => setDate(new Date()));
  const [fromDate, setFromDate] = useState(() =>
    setDate(new Date(new Date().setDate(new Date().getDate() - 24)))
  );
  const [data, setData] = useState([]);
  const [matched, setMatched] = useState(1);
  const [eventType, setEventType] = useState("ALL");

  function setDate(date) {
    return (
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      date.getDate()
    );
  }

  const filterData = () => {
    const newData = fetchedData.filter((values) => {
      if (eventType === "ALL")
        return (
          Date.parse(values.matched_at) <= Date.parse(toDate) &&
          Date.parse(values.matched_at) >= Date.parse(fromDate) &&
          values.is_matched === matched
        );
      return (
        Date.parse(values.matched_at) <= Date.parse(toDate) &&
        Date.parse(values.matched_at) >= Date.parse(fromDate) &&
        values.is_matched === matched &&
        values.event_type_id === Number(eventType)
      );
    });
    setData(newData);
  };

  useEffect(() => {
    filterData();
  }, []);

  const columns = [
    { path: "event_name", name: "Event Name" },
    { path: "selection_name", name: "Nation" },
    { path: "bet_type", name: "Bet Type" },
    { path: "average_rate_matched", name: "User Rate" },
    { path: "stake_matched", name: "Amount" },
    { path: "result", name: "Profit/Loss" },
    { path: "requested_at", name: "Place Date" },
    { path: "matched_at", name: "Match Date" },
  ];

  // const data = [];

  return (
    <div>
      <NavBar />
      <div className="container m-10 mx-auto">
        <TableTop
          pageType = "MyBets"
          toDate={toDate}
          setToDate={setToDate}
          fromDate={fromDate}
          setFromDate={setFromDate}
          filterData={filterData}
          matched={matched}
          setMatched={setMatched}
          eventType={eventType}
          setEventType={setEventType}
        />
        <Table id="eventName" columns={columns} data={data} />
      </div>
    </div>
  );
}
