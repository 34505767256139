import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { removeMyBet, setMyBet } from "../../redux/mybet/mybet.action";
import { postData } from "../../api/apiService";
import { toast } from "react-toastify";

const Stack = [
  {
    text: "50",
    value: 50,
  },
  {
    text: "200",
    value: 200,
  },
  {
    text: "500",
    value: 500,
  },
  {
    text: "1000",
    value: 1000,
  },
  {
    text: "2000",
    value: 2000,
  },
  {
    text: "5000",
    value: 5000,
  },
  {
    text: "10000",
    value: 10000,
  },
  {
    text: "25000",
    value: 25000,
  },
];

function BetSlipComponent({ isBetSlipOpen, bet, handleRemove }) {
  const {
    state: { name, eventId, marketId },
  } = useLocation();
  const dispatch = useDispatch();
  const [oddsValue, setOddsValue] = useState(parseFloat(bet?.rate));
  const [stakes, setStakes] = useState(0);
  let type =
    bet?.type !== ""
      ? bet?.type === "back"
        ? "bg-box1"
        : "bg-box2"
      : "bg-maincard";
  useEffect(() => {
    console.log("bet", bet);
    type =
      bet?.type !== ""
        ? bet?.type === "back"
          ? "bg-box1"
          : "bg-box2"
        : "bg-maincard";
    setOddsValue(parseFloat(bet?.rate));
  }, [bet?.rate]);

  const getMatchType = (matchType) => {
    console.log("matchType", matchType);
    switch (matchType) {
      case "To Win the Toss":
        return "toss";
      case "Tied Match":
        return "tied";
      case "Match Odds":
        return "match";
      default:
        return "fancy";
    }
  };

  const placeBet = async (e) => {
    e.preventDefault();
    console.log("oddsValue", oddsValue, stakes);
    if (oddsValue !== NaN && stakes !== 0) {
      const sideLetter = bet?.type === "back" ? "b" : "l";
      const side =
        bet?.data?.betOpType === "fancy-"
          ? sideLetter
          : `${sideLetter}${bet?.data?.index + 1}`;
      const matchType = getMatchType(bet?.data?.matchType);
      const formdata = new FormData();

      formdata.append("event_id", bet?.data?.eventId);
      formdata.append("market_id", bet?.data?.marketId);
      formdata.append("selection_id", bet?.data?.SelectionId);
      formdata.append("side", side);
      formdata.append("rate", oddsValue);
      formdata.append("price", bet?.price);
      formdata.append("amount", stakes);
      formdata.append("type", matchType);
      console.log("formdata", formdata);
      postData(`/${bet?.data?.betOpType}bets`, formdata, false)
        .then((res) => {
          console.log("response", res);
          if (res?.id) {
            toast.success("Bet placed successfully!");
            handleRemove && handleRemove();
            dispatch(removeMyBet());
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <>
      {isBetSlipOpen && (
        <div>
          <div className={`hidden md:grid grid-cols-3 ${type} mt-4`}>
            <div className="col-span-1 bg-primary-header clip--90 p-3 relative">
              <p className="font-bold text-white">
                BET SLIP
                {/* <span className='mx-2 bg-maincard p-2 text-main uppercase rounded-full text-xs'>CASHout</span> */}
              </p>
            </div>
            <div className="col-span-2 flex flex-col items-end justify-center px-3">
              <button
                onClick={() => {
                  handleRemove && handleRemove();
                  dispatch(removeMyBet());
                }}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 1L1 11M11 11L1 1.00001"
                    stroke="#1B1C1E"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            className={`mt-[-14px] mb-0 p-4 md:m-0 md:p-0 ${type} md:bg-inherit	`}
          >
            <div className="mb-4 lg:my-4 text-lg font-bold">
              <p className="text-sm font-medium">{bet?.data?.runnerName}</p>
              <p>{name}</p>
            </div>
            {/* <p className='mt-4 w-full'>Odds</p> */}
            <div className="flex gap-x-4">
              <div className="flex w-1/2 gap-x-2 bg-white border rounded p-2 border-main">
                <button
                  onClick={() => setOddsValue(oddsValue + 0.1)}
                  className="p-2 px-4 font-bold bg-orange-grad rounded-sm"
                >
                  <div className="relative">
                    <div className="w-[15px] h-[2px] bg-main"></div>
                    <div className="absolute top-[-6px] right-[7px] w-[2px] h-[15px] bg-main"></div>
                  </div>
                </button>
                <input
                  name="odds"
                  type="text"
                  id="odds"
                  value={parseFloat(oddsValue)?.toFixed(2)}
                  className="appearance-none w-full py-2 px-2 text-lg font-semibold text-grey-darker leading-tight focus:outline-none focus:shadow-outline "
                  placeholder="Odds"
                />
                <button
                  onClick={() =>
                    setOddsValue(parseFloat(oddsValue)?.toFixed(2) - 0.1)
                  }
                  className="p-2 px-4 font-bold  bg-orange-grad rounded-sm"
                >
                  <div className="w-[10px] h-[2px] bg-main"></div>
                </button>
              </div>
              <div className="flex w-1/2 gap-x-4">
                <input
                  name="odds"
                  type="text"
                  id="odds"
                  value={stakes}
                  max={25000}
                  onChange={(e) => {
                    if (e.target.value <= 25000) {
                      setStakes(e.target.value);
                    } else {
                    }
                  }}
                  className="appearance-none border px-2 text-lg font-semibold rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline "
                  placeholder=""
                />
              </div>
            </div>
            <div className="grid grid-cols-4 mt-4 gap-4">
              {Stack?.map((item, key) => {
                return (
                  <div
                    onClick={() => setStakes(item?.value)}
                    className="col-span-1 bg-main hover:bg-slate-200 text-white hover:text-main cursor-pointer border-2 border-main text-lg p-1 font-semibold  rounded-lg  text-center "
                  >
                    {item?.text} ₹
                  </div>
                );
              })}
              <button
                onClick={() => setStakes(100)}
                className="col-span-1 bg-primary-header clip-100 text-main text-sm p-2 font-medium rounded-lg text-center"
              >
                MIN
              </button>
              <button
                onClick={() => setStakes(25000)}
                className="col-span-1 bg-maincard text-main text-sm p-2 font-medium rounded-lg text-center"
              >
                MAX
              </button>
              <button className="col-span-1 bg-box1 text-main text-sm p-2 font-medium rounded-lg text-center">
                EDIT STACK
              </button>
              <button
                onClick={() => setStakes("")}
                className="col-span-1 bg-line2 text-main text-sm p-2 font-medium rounded-lg text-center"
              >
                CLEAR
              </button>
              <button
                onClick={() => {
                  handleRemove && handleRemove();
                  dispatch(removeMyBet());
                }}
                className="col-span-2 bg-red clip-100 text-white text-lg p-3 font-semibold rounded-full text-center"
              >
                Cancel Order
              </button>
              <button
                onClick={(e) => placeBet(e)}
                className="col-span-2 bg-green-700 text-white text-lg p-3 font-semibold rounded-full text-center"
              >
                Place Order
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isBetSlipOpen: state.mybetReducer.isBetSlipOpen,
    bet: state.mybetReducer.bet,
    isUserLoggedIn: state.authReducer.isUserLoggedIn,
    user: state.authReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMyBet: (payload) => dispatch(setMyBet(payload)),
    removeMyBet: (payload) => dispatch(removeMyBet(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetSlipComponent);
