import React, { useEffect, useState } from "react";

export default function BetButtonGroup({ marketId, runners, gameId, type }) {
  const [runner, setRunnder] = useState(runners);

  useEffect(() => {
    setRunnder(runners);
  }, [runners]);

  return (
    <div className="flex gap-2 items-center justify-around w-full">
      <div
        className={`p-2 w-1/2 cursor-pointer rounded flex flex-col items-center justify-center ${
          type === "draw" ? "bg-gray-300" : "bg-box1"
        }`}
      >
        <p className="odd-price text-sm font-medium">
          {runner?.backPrice ?? "-"}
        </p>
        <p className="odd-volume text-xs">{runner?.backSize ?? "-"}</p>
      </div>
      <div
        className={`p-2 w-1/2 cursor-pointer rounded flex flex-col items-center justify-center ${
          type === "draw" ? "bg-gray-300" : "bg-box2"
        }`}
      >
        <p className="odd-price text-sm font-medium">
          {runner?.layPrice ?? "-"}
        </p>
        <p className="odd-volume text-xs">{runner?.laySize ?? "-"}</p>
      </div>
    </div>
  );
}
