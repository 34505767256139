import React from 'react'

export default function DepositeRules() {
  return (
    <div className='text-red'>
      <ol type="1">
        <li className='mb-2 text-sm'>1. Deposit money only in the below available accounts to get the fastest credits and avoid possible delays.</li>
        <li className='mb-2 text-sm'>2. Deposits made 45 minutes after the account removal from the site are valid & will be added to their wallets.</li>
        <li className='mb-2 text-sm'>3. Site is not responsible for money deposited to Old, Inactive or Closed accounts.</li>
        <li className='mb-2 text-sm'>4. After deposit, add your UTR and amount to receive balance.</li>
        <li className='mb-2 text-sm'>5. NEFT receiving time varies from 40 minutes to 2 hours.</li>
        <li className='mb-2 text-sm'>6. In case of account modification: payment valid for 1 hour after changing account details in deposit page.</li>
      </ol>
    </div>
  )
}
