import React, { useState } from "react";
import Modal from "../Lib/Model";
import { postData } from "../../api/apiService";

function AddBankAccount({ isAddAcountModalOpen, setIsAddAcountModalOpen }) {
  const [accType, setAccType] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      account_name: e.target.name.value,
      account_type: e.target.accType.value,
      account_number: e.target.acnum.value,
      institution_name: e.target.bank.value,
      institution_number: accType === "upi" ?"NA":e.target.ifsc.value,
    };
    postData("/accounts/withdrawal/create", data)
      .then((res) => {
        // toast.success("Deposit Request Submitted Successfully!", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   transition: Bounce,
        // });
        console.log("Form submitted:", res); //API call here
        setIsAddAcountModalOpen(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div className="w-1/3">
      <Modal
        isOpen={isAddAcountModalOpen}
        onClose={() => setIsAddAcountModalOpen(false)}
      >
        <div className="w-full">
          <h5 className="font-bold text-3xl text-center">Add account</h5>
        </div>
        <form onSubmit={(e) => handleSubmit(e)} className="w-full md:mx-auto">
          <div className="px-10 py-4">
            <label
              className="text-grey-darker text-sm font-medium mb-2 ml-2"
              htmlFor="accType"
            >
              ACCOUNT TYPE
            </label>
            <select
              className="w-full border border-slate-500 p-2 rounded-md mb-4"
              id="accType"
              name="accType"
              required={true}
              value={accType}
              onChange={(e) => setAccType(e.target.value)}
            >
              <option value="">---Select Account Type---</option>
              <option data-label="Bank Account" value="bank_account">
                Bank Account
              </option>
              <option data-label="UPI" value="upi">
                UPI
              </option>
            </select>
            <label
              className="text-grey-darker text-sm font-medium mb-2 ml-2"
              htmlFor="name"
            >
              NAME
            </label>
            <input
              type="text"
              class="w-full border border-slate-500 p-2 rounded-md mb-4"
              name="name"
              id="name"
              required={true}
              placeholder="Enter Name"
            />
            <label
              className="text-grey-darker text-sm font-medium mb-2 ml-2"
              htmlFor="acnum"
            >
              {accType === "upi" ? "UPI ID" : "A/C NUMBER"}
            </label>
            <input
              type={accType === "upi" ? "" : "number"}
              class="w-full border border-slate-500 p-2 rounded-md mb-4"
              name="acnum"
              id="acnum"
              required={true}
              placeholder={
                accType === "upi" ? "Enter UPI ID" : "Enter A/C Number"
              }
            />
            <label
              className="text-grey-darker text-sm font-medium mb-2 ml-2"
              htmlFor="bank"
            >
              {accType === "upi" ? "UPI App Name" : "Bank Name"}
            </label>
            <input
              class="w-full border border-slate-500 p-2 rounded-md mb-4"
              name="bank"
              id="bank"
              placeholder={
                accType === "upi" ? "Enter UPI App Name" : "Enter Bank Name"
              }
            />
            <div className={accType === "upi" ?"hidden":""}>
              <label
                className="text-grey-darker text-sm font-medium mb-2 ml-2"
                htmlFor="ifsc"
              >
                IFSC CODE
              </label>
              <input
                type="text"
                class="w-full border border-slate-500 p-2 rounded-md mb-4"
                name="ifsc"
                id="ifsc"
                required={accType === "upi" ?"":true}
                placeholder="Enter IFSC CODE"
              />
            </div>
            {/* <label
              className="text-grey-darker text-sm font-medium mb-2 ml-2"
              htmlFor="withdrawPass"
            >
              WITHDRAW PASSWORD
            </label>
            <input
              type="text"
              class="w-full border border-slate-500 p-2 rounded-md mb-4"
              name="withdrawPass"
              id="withdrawPass"
              required={true}
              placeholder="Enter withdraw password"
            /> */}
          </div>
          <div className="grid justify-items-center">
            <button
              className="w-1/4 border border-slate-500 rounded-md bg-maincard text-lg font-bold mb-6"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default AddBankAccount;
