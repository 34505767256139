import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { fetchData } from "../../api/apiService";
import DepositRequestTable from "../../components/Deposit/DepositRequestTable";
import BankOptionsList from "../../components/Deposit/BankOptionsList";
import BankDetails from "../../components/Deposit/BankDetails";
import DepositeForm from "../../components/Deposit/DepositeForm";
import DepositeRules from "../../components/Deposit/DepositeRules";

export default function Deposite() {
  const [bankData, setBankData] = useState([]);
  const [selectedData, setSelectedData] = useState();

  const getDepositeData = () => {
    fetchData("/accounts/deposit")
      .then((response) => {
        setBankData(response);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getDepositeData();
  }, []);

  const handleChange = (item) => {
    setSelectedData(item);
  };

  return (
    <div className="">
      <NavBar />
      <div className="grid grid-cols-12 gap-x-4 px-12">
        <div className="col-span-1"></div>
        <div className="col-span-12 lg:col-span-11">
          <div className="grid grid-cols-12 gap-x-4">
            <div className="col-span-12 lg:col-span-8 ">
              <div className="">
                <BankOptionsList
                  listData={bankData}
                  handleChange={handleChange}
                />
              </div>
              {selectedData && (
                <div className="bg-backCard p-4 rounded-sm mt-8 grid grid-cols-2 gap-x-8 px-12">
                  <div className="col-span-2">
                    <p className="underline underline-offset-8 text-center mb-8">
                      {selectedData?.name}
                    </p>
                  </div>
                  <div className="col-span-1">
                    <BankDetails DepositeData={selectedData} />
                  </div>
                  <div className="col-span-1">
                    <DepositeForm DepositeData={selectedData} />
                  </div>
                </div>
              )}

              <div className="bg-backCard p-4 rounded-sm mt-8">
                <DepositeRules />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-4">
              <div className="bg-backCard rounded-sm mt-8">
                <DepositRequestTable />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
