import React, { useState, useEffect } from "react";
import EventsList from "./EventsList";
import { fetchData } from "../../api/apiService";
import EventsHeader from "./EventsHeader";
import eventDataJSON from "./events_api_response.json";

export default function EventsListContainer() {
  const [eventData, setEventData] = useState();
  const [, setLoading] = useState(false);
  const getEventData = () => {
    setLoading(true);
    fetchData(`${process.env.REACT_APP_BASE_BACKEND_URL}/api/events?sportId=4`)
      .then((response) => {
        const cricketData =
          response &&
          response[0]?.filter((item) => {
            return item.sportsName === "cricket";
          });
        const sortCricketData = cricketData.sort(
          (a, b) => Number(b.isInPlay) - Number(a.isInPlay)
        );
        setEventData(sortCricketData);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    // Fetch data initially when component mounts
    getEventData();

    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(getEventData, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="container mx-auto">
      {/* Cricket starts*/}
      <EventsHeader
        eventName="Cricket"
        eventIcon={"/assets/icons/cricket-icon.png"}
        column={[
          {
            name: "1",
            class: "",
          },
          {
            name: "x",
            class: "",
          },
          {
            name: "2",
            class: "",
          },
        ]}
      />
      {/* {loading && <p className='mx-auto text-center my-64 font-lg'>Loading...</p>} */}
      {eventData?.map((d, index) => {
        return <EventsList data={d} />;
      })}
      {/* FootBall starts*/}
      <EventsHeader
        eventName="Football"
        eventIcon={"/assets/icons/football-icon.png"}
      />
      <div className="text-center">
        <img
          className="max-w-[250px] p-4 my-16 mx-auto"
          src="/assets/img/coming-soon.png"
          alt="coming soon"
        />
      </div>
      {/* Tannis starts*/}
      <EventsHeader
        eventName="TENNIS"
        eventIcon={"/assets/icons/tennis-icon.png"}
      />
      <div className="text-center">
        <img
          className="max-w-[250px] p-4 my-16 mx-auto"
          src="/assets/img/coming-soon.png"
          alt="coming soon"
        />
      </div>
    </div>
  );
}
