import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import Table from "./Table";
import TableTop from "./TableTop";
import fetchedData from "./AccountStatementData.json";

export default function AccountStatement() {
  const [toDate, setToDate] = useState(() => setDate(new Date()));
  const [fromDate, setFromDate] = useState(() =>
    setDate(new Date(new Date().setDate(new Date().getDate() - 24)))
  );
  const [data, setData] = useState([]);
  const [eventType, setEventType] = useState("ALL");

  function setDate(date) {
    return (
      date.getFullYear() +
      "-" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(date.getDate() + 1).padStart(2, "0")
    );
  }

  const filterData = () => {
    const newData = fetchedData.filter((values) => {
      if (eventType === "ALL")
        return (
          Date.parse(values.created_at) <= Date.parse(toDate) &&
          Date.parse(values.created_at) >= Date.parse(fromDate)
        );
      return (
        Date.parse(values.created_at) <= Date.parse(toDate) &&
        Date.parse(values.created_at) >= Date.parse(fromDate) &&
        values.event_type_id === Number(eventType)
      );
    });
    setData(newData);
  };

  useEffect(() => {
    filterData();
  }, []);

  const columns = [
    { path: "sr_no", name: "Sr No" },
    { path: "created_at", name: "Date" },
    { path: "amount", name: "Credit" },
    { path: "total", name: "Debit" },
    { path: "balance", name: "Balance" },
    { path: "description", name: "Remark" },
  ];

  return (
    <div>
      <NavBar />
      <div className="container m-10 mx-auto">
        <TableTop
          pageType="AccountStatement"
          toDate={toDate}
          setToDate={setToDate}
          fromDate={fromDate}
          setFromDate={setFromDate}
          filterData={filterData}
          eventType={eventType}
          setEventType={setEventType}
        />
        <Table id="eventName" columns={columns} data={data} />
      </div>
    </div>
  );
}
