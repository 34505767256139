import React from "react";

function WithdrawalRules() {
  return (
    <div className="border rounded-xlarge bg-white p-6 text-red">
      <p>
        1. This form is for withdrawing the amount from the main wallet only.
      </p>
      <p>2. The bonus wallet amount cannot be withdrawn by this form.</p>
      <p>
        3. Do not put Withdraw request without betting with deposit amount. Such
        activity may be identified as Suspicious
      </p>
      <p>
        4. If multiple users are using same withdraw account then all the linked
        users will be blocked.
      </p>
      <p>
        5. Maximum Withdraw time is 45 minutes then only complain on WhatsApp
        number.
      </p>
      <p>6. For reset password contact customer care</p>
    </div>
  );
}

export default WithdrawalRules;
