import React, { useState, useRef, useEffect } from "react";

const DropdownMenu = ({ items, title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // eslint-disable-next-line
  const onOutsideClick = () => {
    setIsOpen(false);
  };
  // const handleItemClick = (item) => {
  //   setIsOpen(false); // Close dropdown after item selection
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        onOutsideClick();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [onOutsideClick]);

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className={`flex gap-x-2 cursor-pointer rounded bg-[#ffffff14] ${
          isOpen ? "bg-maincard text-main" : "bg-[#ffffff14]"
        } p-4 py-2 focus:outline-none`}
      >
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && (
        <div
          ref={ref}
          className="absolute z-10 right-0 mt-2 w-56 bg-white rounded-md overflow-hidden shadow-lg"
        >
          <div className="py-1">{children}</div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
