import React from 'react'
import NavBar from '../components/NavBar'
import EventsListContainer from '../components/EventsListContainer'

export default function Home() {
  return (
    <div>
        <NavBar />
        <div className='container-xl'>
          <EventsListContainer />
        </div>
    </div>
  )
}
