import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  toggleLoginModal,
} from "../../redux/toggle/toggle.action";
import { fetchData } from "../../api/apiService";
import { useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { removeMyBet, setMyBet } from "../../redux/mybet/mybet.action";
import axios from "axios";
import BetSlip from "../RightMenus/BetSlip";
import ReactDOM from "react-dom";
import RunnerList from "./RunnerList";
import FancyRunnerList from "./FancyRunnerList";

function EventListContainer({isUserLoggedIn}) {
  const dispatch = useDispatch();
  const {
    state: { name, date, eventId, marketId },
  } = useLocation();
  const [data, setData] = useState();
  const [market, setMarket] = useState([]);
  const [fancy, setFancy] = useState([]);
  const [matchScoreData, setMatchScoreData] = useState("");
  const [portalContainer, setPortalContainer] = useState(null);

  const [loading, setLoading] = useState(false);
  const getOddsData = () => {
    setLoading(true);
    fetchData(
      `${
        process.env.REACT_APP_BASE_BACKEND_URL
      }/api/odds?marketIds=${marketId?.toString()}&eventId=${eventId}`
    )
      .then((response) => {
        console.log("response >>> ", response);
        if (response) {
       
          setMarket([...market, ...response.market]);
          setFancy([...fancy, ...response.fancy]);
        }
        // setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const getMatchData = () => {
    setLoading(true);
    axios
      .get(`http://13.235.86.130:3000/api/eventScoreData/${eventId}`, {
        headers: {
          Authorization: process.env.REACT_APP_AUTHKEY,
        },
      })
      .then((response) => {
        setMatchScoreData(response);
        setData(response);
        // setLoading(false)
      })
      .catch((err) => {
        console.log("err", err);
        // setLoading(false)
      });
  };
  useEffect(() => {
    // Fetch data initially when component mounts
    getOddsData();

    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(getOddsData, 2000);
    // Clean up interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    getMatchData();
    // const intervalId1 = setInterval(getMatchData, 1500);
    return () => {
      // clearInterval(intervalId1);
    };
  }, []);

  const getStatusClass = () => {};

  const getNumber = (num) => {
    if (num < 1000) {
      return num.toString();
    } else {
      const thousands = Math.floor(num / 1000);
      const remainder = num % 1000;
      if (remainder === 0) {
        return `${thousands}k`;
      } else {
        return `${thousands}.${Math.floor(remainder / 100)}k`;
      }
    }
  };

  const injectComponent = (SelectionId) => {
    const container = document.createElement("div");
    const itemElement = document.getElementById(`${SelectionId}`);
    itemElement.parentNode.insertBefore(container, itemElement.nextSibling);
    setPortalContainer(container);
  };

  const removeComponent = () => {
    if (portalContainer) {
      ReactDOM.unmountComponentAtNode(portalContainer);
      portalContainer.remove();
      setPortalContainer(null);
    }
  };

  const handleMyBet = (Id, SelectionId, rate, price, type, data, index) => {
    
    if(isUserLoggedIn){
      const bet = {
        type: type,
        rate: rate,
        price: price,
        value: "",
        data: {
          marketId: data.marketId,
          eventId: eventId,
          SelectionId: SelectionId,
          index: index,
          ...data,
        },
      };
      dispatch(setMyBet(bet));
      injectComponent(Id);
    }else{
      dispatch(toggleLoginModal(true))
    }
  };

  return (
    <>
      <div className="">
        <div className="bg-gray text-main p-4 flex items-center">
          <img
            onClick={() => window.history.back()}
            className="mr-4"
            width={26}
            src="/assets/icons/back-button.svg"
          />
          <span>
            <img
              className="mr-2"
              width={16}
              src="/assets/icons/cricket-bat-icon.svg"
              alt=""
            />
          </span>
          <p className="font-semibold text-base mr-4">{name} </p>
          <p className="text-body text-xs">
            {moment(date).format("MMM Do, h:mm A")}
          </p>
        </div>
        {/* Cricket starts*/}
        <div
          className="mb-6 mt-2"
          dangerouslySetInnerHTML={{ __html: matchScoreData?.data }}
        ></div>
        <RunnerList 
        market={market ?? []} 
        handleMyBet={handleMyBet} 
        />
        

        {portalContainer &&
          ReactDOM.createPortal(
            <div className="block lg:hidden">
              <BetSlip handleRemove={removeComponent} />
            </div>,
            portalContainer
          )}

          <FancyRunnerList
            fancy={fancy ?? []}
            handleMyBet={handleMyBet}
            getStatusClass={getStatusClass}
            getNumber={getNumber}
          />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isBetSlipOpen: state.mybetReducer.isBetSlipOpen,
    isUserLoggedIn: state.authReducer.isUserLoggedIn,
    user: state.authReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMyBet: (payload) => dispatch(setMyBet(payload)),
    removeMyBet: (payload) => dispatch(removeMyBet(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventListContainer);
