import React from "react";

export default function EventHeader({ eventName, eventIcon, column, center }) {
  return (
    <div className={`grid grid-cols-5 lg:grid-cols-8 items-center bg-line1`}>
      <div className="col-span-3 lg:col-span-3 bg-primary-header p-4 relative">
        <p className="font-bold text-white">
          {eventName}
          {/* <span className='mx-2 bg-maincard p-2 text-main uppercase rounded-full text-xs'>CASHout</span> */}
        </p>
        <img
          className="absolute right-8 top-1 lg:top-2 w-[25px] lg:w-[40px]"
          src={eventIcon}
          alt="game-icon"
        />
      </div>
      <div className="col-span-2 lg:col-span-1">
        <p className="text-xs text-center text-main">MIN:100 - MAX:50K</p>
      </div>
      <div className="lg:hidden flex col-span-3 lg:col-span-1"></div>
      <div
        className={`col-span-2 lg:col-span-4 flex ${
          center ? "justify-center gap-20" : "justify-around gap-2"
        } `}
      >
        {column?.map((item) => {
          return (
            <p
              className={`text-lg lg:text-xs font-medium ${
                item?.class ?? "text-main "
              }`}
            >
              {item?.name}
            </p>
          );
        })}
      </div>
    </div>
  );
}
