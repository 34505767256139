import React, { useState, useEffect } from "react";
import NavBar from "../../components/NavBar";
import { toast, Bounce } from "react-toastify";
import { changePassword } from "../../api/apiService";

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRe, setNewPasswordRe] = useState("");

  function checkPasswordStrength(password) {
    // Initialize variables
    var strength = 0;
    var tips = "";

    // Check password length
    if (password.length < 8) {
      tips += "Password must be atleast 8 characters long. ";
    } else {
      strength += 1;
    }

    // Check for mixed case
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
      strength += 1;
    } else {
      tips += "Use both lowercase and uppercase letters. ";
    }

    // Check for numbers
    if (password.match(/\d/)) {
      strength += 1;
    } else {
      tips += "Include at least one number. ";
    }

    // Check for special characters
    if (password.match(/[^a-zA-Z\d]/)) {
      strength += 1;
    } else {
      tips += "Include at least one special character. ";
    }

    const res = {
      strength: strength,
      Tips: "Error: " + tips,
    };
    // Return results
    return res;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const checkPasswordResult = checkPasswordStrength(newPassword);
    if (newPassword !== newPasswordRe)
      toast.error("The password confirmation does not match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    else if (checkPasswordResult.strength < 4) {
      toast.error(checkPasswordResult.Tips, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } else {
      changePasswordApiCall();
    }
  };

  const changePasswordApiCall = async () => {
    changePassword(oldPassword, newPassword, newPasswordRe)
      .then(async (response) => {
        if (response) {
          toast.success("Password changed successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      });
  };

  return (
    <>
      <NavBar />
      <div className="mt-10 w-1/2 m-auto">
        <form
          id=""
          method="post"
          onSubmit={(e) => handleSubmit(e)}
          novalidate=""
          className="w-full border border-slate-500 rounded-xl p-6"
        >
          <h1 className="text-xl font-bold">CHANGE PASSWORD</h1>
          <br />
          <div className="my-4 ">
            <input
              id="old_password"
              name="old_password"
              required={true}
              type="password"
              placeholder="Old Password"
              className="border border-slate-500 rounded w-full p-2"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className="my-4">
            <input
              id="new_password"
              name="new_password"
              required={true}
              type="password"
              placeholder="New Password"
              className="border border-slate-500 rounded w-full p-2"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <div
              className={newPassword && newPassword.length < 6 ? "" : "hidden"}
            >
              <span className="text-sm text-red">
                Password must be atleast 8 characters long
              </span>
            </div>
          </div>
          <div className="my-4">
            <input
              id="new_password_re"
              name="new_password_re"
              required={true}
              type="password"
              placeholder="Repeat Password"
              className="border border-slate-500 rounded w-full p-2"
              value={newPasswordRe}
              onChange={(e) => setNewPasswordRe(e.target.value)}
            />
            <div
              className={
                newPasswordRe && newPassword !== newPasswordRe ? "" : "hidden"
              }
            >
              <span className="text-sm text-red">
                The password confirmation does not match
              </span>
            </div>
          </div>
          <div className="my-4 w-1/3 m-auto">
            <button
              type="submit"
              className="border border-slate-500 rounded w-full p-2 bg-maincard"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
