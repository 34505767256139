import Cookies from "js-cookie";
import { SET_USER, REMOVE_USER } from "./auth.type";

export const setUser = (payload) => async (dispatch) => {
  dispatch({
    type: SET_USER,
    payload: payload,
  });
};

export const removeUser = () => async (dispatch) => {
  Cookies.remove("XSRF-TOKEN", { path: "/", domain: window.location.hostname });
  Cookies.remove("laravel_session", {
    path: "/",
    domain: window.location.hostname,
  });
  dispatch({
    type: REMOVE_USER,
  });
};
