import React from 'react';
import { useForm } from 'react-hook-form';

const formData = [
  { name: 'firstName', label: 'First Name', type: 'text', required: true },
  { name: 'lastName', label: 'Last Name', type: 'text', required: true },
  { name: 'email', label: 'Email', type: 'email', required: true },
  { name: 'message', label: 'Message', type: 'textarea', required: false }
];

function ScalableForm() {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    // Handle form submission here
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-w-md mx-auto">
      {formData.map((field, index) => (
        <div key={index} className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor={field.name}>
            {field.label}
          </label>
          {field.type === 'textarea' ? (
            <textarea
              {...register(field.name, { required: field.required })}
              id={field.name}
              rows="4"
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors[field.name] ? 'border-red-500' : ''}`}
              placeholder={`Enter ${field.label}`}
            ></textarea>
          ) : (
            <input
              {...register(field.name, { required: field.required })}
              type={field.type}
              id={field.name}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline ${errors[field.name] ? 'border-red-500' : ''}`}
              placeholder={`Enter ${field.label}`}
            />
          )}
          {errors[field.name] && <p className="text-red-500 text-xs italic">Field is required</p>}
        </div>
      ))}
      
      <div className="flex items-center justify-between">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export default ScalableForm;
