import React from "react";
import EventHeader from "./EventHeader";
import { debounce } from "lodash";

const FancyRunnerList = debounce(
  (props) => {
    const handleFancyMyBet = (
      Id,
      SelectionId,
      rate,
      price,
      type,
      data,
      index
    ) => {
      console.log("~~~ ", data, price, rate);
      if (!data.status) {
        if (price != (null || undefined) && rate != (null || undefined)) {
          props?.handleMyBet(Id, SelectionId, rate, price, type, data, index);
        } else {
          console.log("Bet is invalid");
        }
      }
    };

    return (
      <div>
        {props?.fancy?.length > 0 && (
          <EventHeader
            center={true}
            eventName={"Fancy"}
            column={[
              {
                name: "Yes",
                class: "text-blue-600 font-bold lg:text-base",
              },
              {
                name: "No",
                class: "text-red font-bold lg:text-base",
              },
            ]}
            eventIcon={"/assets/icons/cricket-icon.png"}
          />
        )}
        {props?.fancy?.length > 0 &&
          props?.fancy?.map((item, index) => {
            return (
              <div id={`item-${item?.selection_id}-${index}`} className="px-4">
                <div className="grid grid-cols-5 lg:grid-cols-8 items-center border-b-[1px] border-stone-300 py-4">
                  <div className="col-span-3 lg:col-span-3 cursor-pointer">
                    <p className="text-main font-semibold text-md">
                      {item?.odds_name}
                    </p>
                  </div>
                  <div className="hidden md:flex col-span-1 lg:col-span-1  gap-2 justify-around"></div>
                  <div className="col-span-2 lg:col-span-4 flex gap-2 justify-around">
                    <div className="flex gap-2 items-center justify-around w-full">
                      <div className="hidden md:block p-2 w-1/2 "></div>
                      <div className="hidden md:block p-2 w-1/2 "></div>
                      {(item?.game_status === "SUSPENDED" ||
                        item?.game_status === "Ball Running") && (
                        <div
                          className={`p-2 w-full cursor-pointer rounded flex flex-col items-center justify-center bg-white ${props?.getStatusClass(
                            item?.game_status
                          )}`}
                        >
                          {item?.game_status}
                        </div>
                      )}
                      {item?.game_status !== "SUSPENDED" &&
                        item?.game_status !== "Ball Running" && (
                          <>
                            <div
                              onClick={() => {
                                handleFancyMyBet(
                                  `item-${item?.selection_id}-${index}`,
                                  item?.selection_id,
                                  item?.back1_price,
                                  item?.back1_size,
                                  "back",
                                  {
                                    marketId: item.market_id,
                                    eventId: item.event_id,
                                    SelectionId: item.selection_id,
                                    index: index,
                                    status: item?.back1_status,
                                    betOpType: "fancy-",
                                    ...item,
                                  }
                                );
                              }}
                              className={`p-2 w-1/2 cursor-pointer rounded flex flex-col items-center justify-center bg-box1`}
                            >
                              <p className="odd-price text-lg md:text-base font-medium">
                                {item?.back1_price ?? "-"}
                              </p>
                              <p className="odd-volume text-xs">
                                {item?.back1_size ?? "-"}
                              </p>
                            </div>
                            <div
                              onClick={() =>
                                handleFancyMyBet(
                                  `item-${item?.selection_id}-${index}`,
                                  item?.selection_id,
                                  item?.lay1_price,
                                  item?.lay1_size,
                                  "back",
                                  {
                                    data: {
                                      betOpType: "fancy-",
                                      marketId: item.market_id,
                                      eventId: item.event_id,
                                      SelectionId: item.selection_id,
                                      status: item?.lay1_status,
                                      index: index,
                                      ...item,
                                    },
                                  }
                                )
                              }
                              className={`p-2 w-1/2 cursor-pointer rounded flex flex-col items-center justify-center bg-box2`}
                            >
                              <p className="odd-price text-lg md:text-base font-medium">
                                {item?.lay1_price ?? "-"}
                              </p>
                              <p className="odd-volume text-xs">
                                {item?.lay1_size ?? "-"}
                              </p>
                            </div>
                          </>
                        )}

                      {/* <div className='p-2 w-full'></div> */}
                      <div className="hidden md:block p-2 w-full">
                        <p className="text-[10px]">
                          Min Bet: {props?.getNumber(item?.minimum_bet)}
                        </p>
                        <p className="text-[10px]">
                          Max Market:{props?.getNumber(item?.maximum_bet)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  },
  100
);
export default FancyRunnerList;
