import { UPDATE_WALLET } from "./wallet.type";

const INITIAL_STATE = {
  balance: "",
  decimal_places: 2,
};

const walletReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_WALLET:
      return {
        ...state,
        balance: action.payload.balance,
        decimal_places: action.payload.decimal_places,
      };
    default:
      return state;
  }
};

export default walletReducer;
