import React from "react";
import EventHeader from "./EventHeader";
import EventButtonGroup from "../EventButtonGroup";
import { debounce, isUndefined } from "lodash";

const RunnerList = debounce(
  (props) => {
  return (
    <div>
      {props?.market?.length > 0 &&
        props?.market?.map((item, index) => {
          console.log("item", item);
          return (
            <>
              {item?.runnerCount > 0 && (
                <EventHeader
                  eventName={item?.name}
                  eventIcon={"/assets/icons/cricket-icon.png"}
                  column={[
                    {
                      name: "BACK",
                      class: "text-blue-600 font-bold lg:text-base",
                    },
                    {
                      name: "LAY",
                      class: "text-red font-bold lg:text-base",
                    },
                  ]}
                />
              )}
              {item?.runnerCount > 0 &&
                item?.runners?.map((singleRunner, key) => {
                  return (
                    <div className="px-4">
                      <div
                        id={`item-${singleRunner?.SelectionId}-${index}`}
                        className="grid grid-cols-5 lg:grid-cols-8 items-center border-b-[1px] border-stone-300 py-4"
                      >
                        <div className="col-span-3 lg:col-span-3 cursor-pointer">
                          <p className="text-main font-semibold text-md">
                            {singleRunner?.runnerName}
                          </p>
                        </div>
                        <div className="hidden md:col-span-1 lg:col-span-1 md:flex gap-2 justify-around"></div>
                        <div className="col-span-2 md:col-span-5 lg:col-span-4 flex gap-2 justify-around">
                          <div className="flex gap-2 items-center justify-around w-full">
                            {console.log(
                              "item?.status back",
                              singleRunner?.ExchangePrices?.AvailableToBack?.sort()
                            )}
                            <EventButtonGroup
                              handleClick={props?.handleMyBet}
                              Id={`item-${singleRunner?.SelectionId}-${index}`}
                              SelectionId={singleRunner?.SelectionId}
                              type="back"
                              ExchangePrices={
                                singleRunner?.ExchangePrices?.AvailableToBack
                              }
                              data={{
                                betOpType: "",
                                marketId: item.marketId,
                                matchType: item.name,
                                ...singleRunner,
                              }}
                            />
                            <EventButtonGroup
                              handleClick={props?.handleMyBet}
                              Id={`item-${singleRunner?.SelectionId}-${index}`}
                              SelectionId={singleRunner?.SelectionId}
                              type="lay"
                              ExchangePrices={
                                singleRunner?.ExchangePrices?.AvailableToLay
                              }
                              data={{
                                betOpType: "",
                                marketId: item.marketId,
                                matchType: item.name,
                                ...singleRunner,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </>
          );
        })}
    </div>
  );
}, 100);
export default RunnerList;
